export const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.defer = true;
    script.async = true;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });

export const loadCanny = () => {
  loadScript('https://www.googletagmanager.com/gtag/js?id=G-JKFDZ57PK8');
  (function (w, d, i, s) {
    function l() {
      if (!d.getElementById(i)) {
        var f = d.getElementsByTagName(s)[0];
        var e = d.createElement(s);
        e.type = 'text/javascript';
        e.async = !0;
        e.src = 'https://canny.io/sdk.js';
        f.parentNode.insertBefore(e, f);
      }
    }
    if ('function' != typeof w.Canny) {
      var c = function () {
        c.q.push(arguments);
      };
      c.q = [];
      w.Canny = c;
      'complete' === d.readyState
        ? l()
        : w.attachEvent
        ? w.attachEvent('onload', l)
        : w.addEventListener('load', l, !1);
    }
  })(window, document, 'canny-jssdk', 'script');
};

export const preLoadCanny = () => {
  if (!window.Canny) {
    loadCanny();
    return false;
  }
  return true;
};
