import { AppConfig } from '../../config';
import * as GA_EVENTS from './index';
import { DEMO_EDITOR_KEYS } from '../../constants';
import { updateUserData } from 'config/models/user';
import { defaultUserData } from 'rmw-shell/lib/utils/auth';

export function init() {
  if (AppConfig.gaEnabled) {
    var _gaq = window.dataLayer || [];
    window.GA = {};
    window.GA.trackGaEvent = function () {
      _gaq.push(arguments);
    };
    window.GA.trackGaEvent('js', new Date());
    window.GA.trackGaEvent('config', AppConfig.gaTrackingKey);
  }
}

export function captureEvent(
  eventName,
  eventParams,
  userInfo,
  updateAuth = null,
) {
  if (eventName === GA_EVENTS.resumeDownloadPdfClick && window.tp && userInfo) {
    console.log('Download_resume');
    const lastTPRequest = localStorage.getItem(
      DEMO_EDITOR_KEYS.TRUST_PILOT_REQUEST,
    );
    const { uid, email, displayName, downloadCount = 1 } = userInfo;
    if (!lastTPRequest) {
      const trustpilot_invitation = {
        recipientEmail: email,
        recipientName: displayName,
        referenceId: uid,
        source: 'InvitationScript',
      };
      if (downloadCount > 10) {
        window.tp('createInvitation', trustpilot_invitation);
        localStorage.setItem(
          DEMO_EDITOR_KEYS.TRUST_PILOT_REQUEST,
          new Date().getTime(),
        );
      }
    }
    try {
      if (userInfo.isAuthenticated) {
        const newDownloadCount = downloadCount + 1;
        updateUserData(userInfo.uid, { downloadCount: newDownloadCount });
        updateAuth &&
          updateAuth({
            ...defaultUserData(userInfo),
            downloadCount: newDownloadCount,
          });
      } else {
        eventName = eventName + '_demo';
      }
    } catch (err) {
      console.log(err);
    }
  }
  window.GA.trackGaEvent('event', eventName, eventParams);
}
