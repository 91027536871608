export const createNewUser = async function (user) {
  const { doc, setDoc, getFirestore } = await import('firebase/firestore');
  const db = getFirestore();
  const cityRef = doc(db, 'users', user.uid);
  let firstName = user.firstName;
  let lastName = user.lastName;
  if ((!firstName || !lastName) && user.displayName) {
    const [first, last] = user.displayName.split(' ');
    firstName = first;
    lastName = last;
  }
  setDoc(
    cityRef,
    {
      capital: true,
      displayName: user.displayName,
      uid: user.uid,
      photoURL: user.photoURL,
      providers: user.providerData,
      emailVerified: user.emailVerified,
      isAnonymous: user.isAnonymous,
      email: user.email,
      firstName,
      lastName,
    },
    { merge: true },
  );
};

export const getUserData = async function (user) {
  const { doc, getDoc, getFirestore } = await import('firebase/firestore');
  const db = getFirestore();
  const docRef = doc(db, 'users', user.uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData = docSnap.data();

    return docData;
  }
  return false;
};

export const updateUserData = async function (uid, updatedUserData = {}) {
  const { doc, setDoc, getFirestore } = await import('firebase/firestore');
  const db = getFirestore();
  const docRef = doc(db, 'users', uid);

  setDoc(docRef, updatedUserData, { merge: true });
};
