import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute';
import React, { lazy } from 'react';
import UnauthorizedRouteV2 from './auth/UnauthorizedRouteV2';

const SignIn = lazy(() => import('../pages/Signin'));

const PageNotFound = lazy(() => import('../pages/PageNotFound'));

const getDefaultRoutes = (appConfig) => {
  return [
    {
      path: '/signin',
      exact: true,
      element: (
        <UnauthorizedRouteV2>
          <SignIn redirectTo={appConfig?.auth?.redirectTo || '/'} />
        </UnauthorizedRouteV2>
      ),
    },

    {
      path: '*',

      element: (
        <AuthorizedRoute>
          <PageNotFound />
        </AuthorizedRoute>
      ),
    },
  ];
};

export { getDefaultRoutes };
