import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'base-shell/lib/providers/Auth';
import { useConfig } from 'base-shell/lib/providers/Config';
import useIsDemoEditor from 'components/common/customHooks/useIsDemoEditor';

function UnauthorizedRouteV2({ children, redirectTo = '/', ...rest }) {
  const { appConfig } = useConfig();
  let location = useLocation();
  const { auth: authConfig } = appConfig || {};
  const { redirectTo: _redirectTo = redirectTo } = authConfig || {};
  const { auth } = useAuth();
  const state = { ...location.state };
  const { isDemoEditor } = useIsDemoEditor();
  if (isDemoEditor) {
    state.fromDemoEditor = true;
  }

  let from = new URLSearchParams(location.search).get('from') || _redirectTo;

  if (!auth.isAuthenticated) {
    return children;
  } else {
    return <Navigate to={from} replace state={state} />;
  }
}

export default UnauthorizedRouteV2;
