export const routeClick = 'route_click';
export const resumeEditClick = 'resume_edit_click';
export const CoverEditClick = 'cover_edit_click';
export const resumeDuplicateClick = 'resume_duplicate_click';
export const coverDuplicateClick = 'cover_duplicate_click';
export const resumeDownloadPdfClick = 'resume_download_click';
export const coverDownloadPdfClick = 'cover_download_click';
export const resumeDeleteClick = 'resume_delete_click';
export const coverDeleteClick = 'cover_delete_click';
export const backToEditorClick = 'back_to_editor_click';

export const myAccountMenuClick = 'my_account_menu_click';
export const myMenuResumeClick = 'my_menu_resume_click';
export const mySignoutMenuClick = 'my_menu_signout_click';
export const deleteAccountClick = 'delete_account_click';
export const useAiWriter = 'use_ai_writer';
export const generateWithAi = 'generate_with_ai';
export const uploadLinkedinFile = 'upload_file_on_linkedin';
export const earnFreeCredits = 'earn_free_credits';
export const myOrdersMenuClick = 'my_orders_menu_click';
export const myScanHistoryClick = 'my_scan_history_click';
export const myFreeCreditsMenuClick = 'my_free_credits_request_click';
export const paymentsClick = 'payment_click';

export const useJDWriter = 'use_jd_writer';
export const generateWithJD = 'generate_with_jd';
export const openShareDialog = 'open_share_dialog';
export const resumePublished = 'resume_published';

export const generateContentAiMenu = 'generate_content_ai_menu';
export const improveWritingAiMenu = 'improving_writing_ai_menu';
export const makeShorterAiMenu = 'make_shorter_ai_menu';
export const makeLongerAiMenu = 'make_longer_ai_menu';
export const correctGrammerAiMenu = 'correct_grammer_ai_menu';
export const quantifyTextAiMenu = 'quantify_text_ai_menu';
export const acceptImproveWriting = 'accept_improve_writing';
export const editPublicTemplate = 'edit_public_template';

export const scanResumeButton = 'scan_resume_button';
export const getScoreButton = 'get_score_button';
