import red from '@mui/material/colors/red';
import pink from '@mui/material/colors/pink';
import green from '@mui/material/colors/green';

export const IR_THEME = {
  palette: {
    primary: { main: '#0bdbb6', contrastText: '#fff' },
    secondary: { main: '#ff9800' },
    background: { paper: 'rgb(239, 242, 249)' },
    text: { contrastText: '#F6CAC8' },
    secondary: {
      main: '#FBB03B',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      '"sans-serif"',
      '"system-ui"',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
};
const themes = [
  {
    id: 'default',
    source: {
      palette: {
        primary: { main: '#343434' },
        secondary: {
          main: '#c62828',
        },
        background: { paper: 'rgb(239, 242, 249)' },
      },
    },
  },
  {
    id: 'red',
    color: red[500],
    source: {
      palette: {
        primary: red,
        secondary: pink,
        error: red,
        background: { paper: '#EFF2F9' },
      },
    },
  },
  {
    id: 'green',
    color: green[500],
    source: {
      palette: {
        primary: green,
        secondary: red,
        error: red,
        background: { paper: 'rgb(239, 242, 249)' },
      },
    },
  },
  {
    id: 'standard',
    source: {
      ...IR_THEME,
    },
  },
];

export default themes;
