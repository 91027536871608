import React from 'react';
import { useLocation } from 'react-router-dom';

const useIsDemoEditor = () => {
  const location = useLocation();

  const isDemoEditor = !!location.pathname
    ? location.pathname.includes('demo')
    : false;
  return {
    isDemoEditor,
  };
};

export default useIsDemoEditor;
