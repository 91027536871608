import React from 'react';
import App from 'base-shell/lib';
import { init } from './utils/eventTracking';
import config from './config';

export default function () {
  React.useEffect(() => {
    init();
  }, []);
  return <App config={config} />;
}
