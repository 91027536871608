import React from 'react';
import { render } from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister({
  onUpdate: (reg) => {
    console.log('called_on_update:', reg);
    window.update = () => {
      try {
        reg.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      } catch (error) {
        console.warn('error', error);
        window.update = null;
      }
    };
  },
});
